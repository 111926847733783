@layer maklersoft {
  body{
    margin: 0;
    --p-tabs-tablist-border-width: 0;
    --p-tabs-tab-border-width: 0;
  }

  .loader{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica, sans-serif;
    font-size: 26px;
    color: #72727d;
  }

  .p-tab{

  }

  .p-inputotp-input{
    border: 1px solid grey;
  }
}


